import React, { createContext, useEffect, useState } from 'react'
import Hero from '../components/Homepage/Hero'
import UserForm from '../components/Quiz/UserForm'
import Questions from '../components/Quiz/Questions'

import { callApi } from '../../config/api'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

export const QuizContext = createContext();


const Quiz = () => {
    const [questions, setQuestions] = useState([]);
    const [userId, setUserId] = useState();
    const [showThankyou, setShowThankyou] = useState(false);
    const [score, setScore] = useState();

    const [userCanPlay, setUserCanPlay] = useState(false)

    useEffect(() => {
        callApi.get('/questions.php')
            .then(({ data }) => {
                setQuestions(data);
            }).catch(err => {
                console.error(err.message);

            })
        return () => {

        }
    }, [])

    const [isFormVisible, setIsFormVisible] = useState(false)

    // const data = useContext(QuizContext)


    const updateQuestion = (id, isChecked, selectedValue) => {
        // console.log("iran", id, isChecked, selectedValue);
        // Find the question by id and update its properties
        setQuestions(prevQuestions => {
            return prevQuestions?.map(question => {
                if (question.id === id) {
                    return {
                        ...question,
                        isChecked,
                        selectedValue
                    };
                }
                return question;
            });
        });
    };
    const updateUser = (id) => {
        setUserId(id)
    }
    const updateThankyou = (value) => {
        setShowThankyou(value)
    }
    const updateScore = (value) => {
        setScore(value)
    }

    const updateCanPlay = (value) => {
        setUserCanPlay(value)
    }

    const navigate = useNavigate()


    return (
        <div>
            <QuizContext.Provider value={{ userId, updateUser, questions, updateQuestion, showThankyou, updateThankyou, score, updateScore, userCanPlay, updateCanPlay }}>
                <Hero />
                {!isFormVisible
                    ? <UserForm setIsFormVisible={setIsFormVisible} isFormVisible={isFormVisible} setUserCanPlay={setUserCanPlay} />
                    : userCanPlay ? <Questions />
                        : <>
                            <p style={{ fontSize: 24, textAlign: 'center', padding: "100px 24px 24px 24px" }}>You have already taken quiz today!</p>
                            <div style={{ textAlign: 'center', marginTop: 40 }}>
                                <Button style={{ marginBottom: 24 }} className="step-btn" onClick={() => {
                                    navigate("/");
                                    window.scrollTo(0, 0)
                                }}>Go Back</Button>
                            </div>
                        </>}
            </QuizContext.Provider >
        </div>
    )
}

export default Quiz