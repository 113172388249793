import { Button } from 'antd'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { QuizContext } from '../../screens/Quiz'
const ThankYou = () => {
    const data = useContext(QuizContext)
    const navigate = useNavigate()
    return (
        <div id='thankyou'>

            <div id="score">

                <h2>You Have Scored</h2>
                <h1>{data?.score}</h1>
            </div>
            <h2 className="section-title">Thank You</h2>
            {/* <h3 style={{ textAlign: 'center' }}>You response has been </h3> */}


            <div style={{ textAlign: 'center', marginTop: 40 }}>
                <Button className="step-btn" onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0)
                }}>Go Back</Button>
            </div>
        </div>
    )
}

export default ThankYou