import React, { useContext, useEffect, useRef, useState } from 'react'
import QuestionItem from './QuestionItem'
import { QuizContext } from '../../screens/Quiz'
import { Button, Progress, notification } from 'antd';
import ThankYou from './ThankYou'
import { useTimer } from 'react-timer-hook';

import { callApi } from '../../../config/api';



function MyTimer({ expiryTimestamp, stopNow }) {


    const postAnswers = () => {
        const filteredAnswers = []
        data?.questions?.map(q => {
            if (q?.isChecked) {
                filteredAnswers.push({ qid: q?.id, selectedValue: q?.selectedValue })
            }
        })
        callApi.post("/quiz_result.php", { selectedAnswers: filteredAnswers, userId: data?.userId }, { headers: { 'Content-Type': 'application/json', } })
            .then((res) => {
                // console.log(res?.data)
                data.updateScore(res?.data?.score)
                data.updateThankyou(true)

            })
            .catch(err => {
                console.error(err.message)
                notification.open({
                    message: "Something Happened! Please Try Again."
                })
            })
    }

    const data = useContext(QuizContext)
    const {
        seconds,
        minutes,
        start,
        pause
    } = useTimer({
        expiryTimestamp, autoStart: false, onExpire: () => {
            postAnswers()
            // console.log(data?.questions)
        }
    });
    useEffect(() => {
        if (data?.questions[0]?.isChecked) {
            start()
        }
        if (stopNow) {
            pause()
        }
    }, [data.questions, stopNow])

    return (
        <div style={{ textAlign: 'center' }}>
            <div className='timer' >
                <span>{minutes}</span>:<span>{seconds}</span>
            </div>
            {/* <p>{isRunning ? 'Running' : 'Not running'}</p> */}
            {/* <button onClick={start}>Start</button> */}


        </div>
    );
}

const Questions = () => {
    const [isScoreLoading, setIsScoreLoading] = useState()
    // const [score, setScore] = useState()




    const [pauseTimer, setPauseTimer] = useState(false)

    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView()
    // run this function from an event handler or an effect to execute scroll 
    useEffect(() => {
        executeScroll()
    }, [])

    // const [showThankYou, setShowThankYou] = useState(false)
    const data = useContext(QuizContext)

    const steps = data?.questions?.map(item => ({
        title: item.question,
        content: <QuestionItem q={item} />
    }))
    const [current, setCurrent] = useState(0);
    const next = () => setCurrent(current + 1)
    // const prev = () => setCurrent(current - 1)

    const [percentage, setPercentage] = useState(0)


    const contentStyle = {
        textAlign: 'center',
        color: "#595959",
        margin: 24

    };
    if (data?.showThankyou) {
        return <ThankYou />
    } else {
        const time = new Date();
        // time.setSeconds(time.getSeconds() + 10); // 10 minutes timer
        time.setSeconds(time.getSeconds() + 90 + 90); // 10 minutes timer
        return (
            <div style={{ position: 'relative' }}>
                <div id='questions' ref={myRef} >
                    <h2 className="section-title">Question {`(${current + 1}/${data.questions.length})`} </h2>
                    <div className='timer-wrapper'>
                        <MyTimer expiryTimestamp={time} autoStart={false} stopNow={pauseTimer} />
                    </div>
                    <Progress
                        className='progress-bar'
                        percent={percentage}
                        strokeColor={"#ff6600"}
                        type='line'
                    // format={() => `${Math.ceil((current + 1) * 100 / data.questions.length)}%`}
                    />

                    <div style={contentStyle}>{steps[current]?.content}</div>
                    <div
                        style={{
                            marginTop: 30,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >

                        {current < steps.length - 1 && (
                            <Button
                                size='large'
                                className='step-btn'
                                type="primary"
                                onClick={() => {
                                    next();
                                    setPercentage(Math.ceil((current + 1) * 100 / data.questions.length))
                                }}
                                disabled={data.questions[current].isChecked ? false : true}

                            >
                                Next
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button
                                loading={isScoreLoading}
                                size='large'
                                className='step-btn'
                                type="primary"
                                disabled={data.questions[current].isChecked ? false : true}
                                onClick={() => {
                                    setPercentage(100)
                                    setPauseTimer(true)

                                    const filteredAnswers = []
                                    data?.questions?.map(q => {
                                        if (q?.isChecked) {
                                            filteredAnswers.push({ qid: q?.id, selectedValue: q?.selectedValue })
                                        }
                                    })
                                    setIsScoreLoading(true)
                                    callApi.post("/quiz_result.php", { selectedAnswers: filteredAnswers, userId: data?.userId }, { headers: { 'Content-Type': 'application/json', } })
                                        .then((res) => {
                                            // console.log(res.data)
                                            setIsScoreLoading(false)
                                            data?.updateScore(res?.data?.score)
                                            data.updateThankyou(true)
                                        })
                                        .catch(err => {
                                            setIsScoreLoading(false)
                                            console.error(err.message)
                                            notification.open({
                                                message: "Something Happened! Please Try Again."
                                            })
                                        })

                                }}
                            >
                                Submit
                            </Button>
                        )}
                        {/* {current > 0 && (
                    <Button
                        className='step-btn'
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Previous
                    </Button>
                )} */}
                    </div>
                    {/* {questionsData?.map(q => <QuestionItem q={q} />)} */}

                </div >
            </div>
            // <div>Questions {isLoading ? "Loading..." : questions?.length > 0 && JSON.stringify(questions)}</div>
        )
    }
}

export default Questions