import React, { useRef, useState } from 'react'

import { } from "@ant-design/icons"
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaPhone, FaPhoneAlt, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from 'react-icons/fa6';
import { appstore, playStore } from '../../assets/images';
import { Button } from 'antd';


const Footer = () => {
    const homepage = "https://fast-cables.com/"
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const formRef = useRef(null)
    return (
        <>
            <footer>
                <div className="border"></div>
                <div className="footer-header">
                    <img src="./fast-cable-logo.png" alt="" />
                    <h2 className="footer-header_text-links">
                        <a href={homepage + "cables/"}>  Cables</a> |
                        <a href={homepage + "lights/"}>  Lights</a> |
                        <a href={homepage + "metals/"}> Metals</a> |
                        <a href={homepage + "pvc/"}> PVC</a></h2>
                </div>
                <div className="footer-columns">
                    <div className="footer-column">
                        <h2 className='footer-heading'>COMPANY</h2>
                        <ul>
                            <li><a href={homepage + "about-us"}>About Us</a></li>
                            <li><a href={homepage + "quality-standards"}>Quality Standards</a></li>
                            <li><a href={homepage + "careers"}>Careers</a></li>
                            <li><a href={homepage + "network"}>Networks</a></li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <h2 className='footer-heading'>Services</h2>
                        <ul>
                            <li><a href={homepage + "fast-tasdeeq"}>Fast Tasdeeq</a></li>
                            <li><a href={homepage + "fast-tasdeeq-2"}>Fast Tasdeeq Plus</a></li>
                            <li><a href={homepage + "fast-tasdeeq-2/#fastapp"}>Fast App</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h2 className='footer-heading'>Contact Info</h2>
                        <ul className='social-links'>
                            <li><a href="tel:042-111-000-343"><FaPhone color='#f36f31' />042-111-000-343</a></li>
                            <li><a href="mailto:info@fast-cables.com"><FaEnvelope color='#f36f31' />info@fast-cables.com</a></li>
                            <li><a style={{ paddingRight: 24 }} href="https://maps.app.goo.gl/E1VFx266N4gDkJR47"><FaMapMarkerAlt color='#f36f31' />192-Y Commercial Area, DHA Phase III, Lahore, Pakistan</a></li>

                        </ul>
                    </div>
                    <div className="footer-column">
                        <h2 className='footer-heading'>Get in Touch</h2>
                        <form ref={formRef} onSubmit={e => {
                            e.preventDefault();
                            setIsLoading(true)
                            setTimeout(() => {
                                setIsLoading(false)
                                setEmail("")
                            }, 1000);
                        }}>
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                            {/* <input type="submit" value="Subscribe" /> */}
                            <Button
                                className='submit-btn'
                                htmlType="submit"
                                loading={isLoading}
                                style={{ height: 40, color: "#f36f31", border: "1px solid #000" }} >Subscribe</Button>
                        </form>
                        <div className="socials">
                            <div>
                                <a href="https://linkedin.com/company/fast-cables-limited"><FaLinkedin /></a>
                                <a href="https://twitter.com/FastCablesLtd"><FaXTwitter /></a>
                                <a href="https://www.facebook.com/fastcablesltd/"><FaFacebook /></a>
                                <a href="https://pk.linkedin.com/company/fast-cables-limited"><FaInstagram /></a>
                                <a href="https://www.youtube.com/@fastcables2063"><FaYoutube /></a>
                            </div>
                            <div><a className='img-link' href="https://apps.apple.com/pk/app/fast-app/id1610167312">
                                <img src={appstore} alt="Appstore" />
                            </a>
                                <a className='img-link' href="https://play.google.com/store/apps/details?id=com.fast_cables.apex.twa&fbclid=IwAR1GcihZvEI8fNPgMCDCjPv91s_azNeS7uC8ZOB4QwJJga5SjVQUw_WBPjY&pli=1">
                                    <img src={playStore} alt="Playstore" />
                                </a></div>
                        </div>

                    </div>

                </div>

                {/* <div id="footer">
                <div className="column col-1">
                    <a href="https://www.fast-lights.com/"><img src="/footer-logo.png" alt="Logo" /></a>

                    <p>© 2023 Fast Lights All Rights Reserved Website Designed and Developed By <a href="https://bramerz.com/" target='_blank'>Bramerz Digital</a></p>
                </div>
                <div className="column col-2">
                    <h2 className='footer-heading'>CUSTOMER RELATIONS</h2>
                    <ul>
                        <li>
                            <a href="https://www.fast-lights.com/privacy-policy/">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://www.fast-lights.com/terms-and-conditions/">Terms and Conditions</a>
                        </li>
                    </ul>
                </div>
                <div className="column col-3">
                    <h2 className='footer-heading'>Contact Us</h2>
                    <ul className='icon-list'>
                        <li style={{ display: 'flex' }}>
                            <div style={{ width: 24 }}>
                                <FaMapMarkerAlt size={20} color='#f47629' />
                            </div>
                            <p>Sales Office & Display Center 120 - Y Commercial Area , DHA Phase III, Lahore, Pakistan</p>
                        </li>
                        <li className='icon-item'>
                            <div style={{ width: 24 }}>
                                <FaPhoneAlt size={20} color='#f47629' />
                            </div>
                            <p><a href="tel:042111000343">042-111-000-343</a></p>
                        </li>
                        <li className='icon-item'>
                            <div style={{ width: 24 }}>
                                <FaEnvelope size={20} color='#f47629' />
                            </div>
                            <p><a href="mailto:info@fast-lights.com">info@fast-lights.com</a></p>
                        </li>
                    </ul>
                </div>
                <div className="column col-4">
                    <iframe style={{ border: 0 }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13611.52929658684!2d74.3766526!3d31.4724231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b7684cdb5e317c6!2sFast%20Lights!5e0!3m2!1sen!2s!4v1666348703687!5m2!1sen!2s" width="100%" height="250" allowFullScreen=""></iframe>
                </div>

            </div> */}
            </footer >
            <div className='footer-bottom' >
                <p class="has-text-color" style={{ color: "#c1c1c1", fontSize: 16 }}>All Rights Reserved. Designed and Developed by&nbsp;<a href="https://bramerz.com/" target="_blank" rel="noreferrer noopener">Bramerz  Digital</a>.</p>

            </div>
        </>

    )
}

export default Footer