import React from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from './screens/Home';
import Quiz from './screens/Quiz';


const Main = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/quiz",
            element: <Quiz />,
        },
    ]);
    return (
        <div id="main">
            <Header />
            <RouterProvider router={router} />
            <Footer />
        </div>
    )
}

export default Main