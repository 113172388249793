import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Select, notification } from 'antd';
import { QuizContext } from "../../screens/Quiz"
import { MaskedInput } from 'antd-mask-input';

import { callApi } from '../../../config/api';

const UserForm = ({ setIsFormVisible, isFormVisible, }) => {

    const cities = [
        "Karachi",
        "Lahore",
        "Faisalabad",
        "Rawalpindi",
        "Gujranwala",
        "Peshawar",
        "Multan",
        "Saidu Sharif",
        "Hyderabad",
        "Islamabad",
        "Quetta",
        "Cantonment",
        "Wahga",

    ]
    const myRef = useRef(null)

    const executeScroll = () => myRef?.current?.scrollIntoView()
    // run this function from an event handler or an effect to execute scroll 
    useEffect(() => {
        executeScroll()
    }, [])


    const [isLoggedIn, setisLoggedIn] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const [canPlay, setCanPlay] = useState(false)
    const [message, setMessage] = useState("")

    const [toggleRegister, setToggleRegister] = useState(false)
    // First check if the user is logged in 

    const data = useContext(QuizContext)


    useEffect(() => {
        setIsLoading(true)
        callApi.get("/check_session.php").then((res) => {

            // console.log(res?.data);
            setIsLoading(false);
            setisLoggedIn(res?.data?.loggedIn)
            data?.updateUser(res?.data?.username)
            // setUserCanPlay(false)
            data?.updateCanPlay(res?.data?.canPlay)
            // console.log(res?.data?.canPlay);
            // console.log("User Can Play", res)
            setMessage(res?.data?.message)


            if (isLoggedIn) {
                setIsFormVisible(true)
                data?.updateCanPlay(res?.data?.canPlay)

            }
        }).catch(err => {
            console.error(err.message)
            setIsLoading(false)
        })

        return () => { }

    }, [isLoggedIn])


    const [isRegisterLoading, setIsRegisterLoading] = useState(false)

    const onFinish = (values) => {
        delete values.confirm

        setIsRegisterLoading(true)

        callApi.post("/register.php", values, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                // console.log(res?.data)
                if (res?.data?.loggedIn) {
                    // console.log("user loggend in successfully! now show quiz form.")
                    setIsFormVisible(true)
                    setIsRegisterLoading(false)
                    data?.updateUser(res?.data?.username)

                } else {
                    notification.open({
                        message: res?.data?.message
                    })
                    setIsRegisterLoading(false)
                }
            })
            .catch(err => {
                console.error(err.message)
                setIsRegisterLoading(false)
            })
        // setIsFormVisible(false)

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [isLoginLoading, setIsLoginLoading] = useState(false)
    const onLoginFinish = (values) => {

        setIsLoginLoading(true)

        callApi.post("/login.php", values, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                // console.log(response?.data)
                // console.log("Form Visibility", isFormVisible);

                if (response?.data?.loggedIn) {
                    // console.log("user loggend in successfully! now show quiz form.")
                    // setIsFormVisible(true)
                    // data?.updateUser(response?.data?.username)
                    // data?.updateCanPlay(response?.data?.canPlay)



                    callApi.get("/check_session.php").then((res) => {

                        // console.log(res?.data);
                        setIsLoading(false);
                        setisLoggedIn(res?.data?.loggedIn)
                        data?.updateUser(res?.data?.username)
                        // setUserCanPlay(false)
                        data?.updateCanPlay(res?.data?.canPlay)
                        // console.log(res?.data?.canPlay);
                        // console.log("User Can Play", res)
                        setMessage(res?.data?.message)


                        // if (isLoggedIn) {
                        //     setIsFormVisible(true)
                        //     data?.updateCanPlay(res?.data?.canPlay)

                        // }
                    }).catch(err => {
                        console.error(err.message)
                        setIsLoading(false)
                    })

                    setIsLoginLoading(false)



                    // data.userId = response?.data?.username
                } else {
                    notification.open({
                        message: response?.data?.message
                    })
                    setIsLoginLoading(false)
                }
            })
            .catch(err => {
                console.error(err.message)
                setIsLoginLoading(false)
            })
        // setIsFormVisible(false)

    };
    const onLoginFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    if (isLoading) {
        return <>Loading...</>
    } else {


        if (!isLoggedIn) {
            return (
                <div ref={myRef}>

                    {!toggleRegister ? <div id='user-detail-form'>
                        <h2 className='section-title'>Login  Form</h2>
                        <Form

                            requiredMark={false}
                            name="user-details"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}

                            onFinish={onLoginFinish}
                            onFinishFailed={onLoginFinishFailed}
                            autoComplete="off"
                        >

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input your email!' },
                                    { type: "email", message: 'Please input correct email!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>


                            <Form.Item className='submit-item' wrapperCol={{ span: 24 }} style={{ marginBottom: 16 }}>
                                <Button loading={isLoginLoading} className='start-btn' type="primary" htmlType="submit" size='large' shape='round'>
                                    Login
                                </Button>
                            </Form.Item>
                            <div style={{ display: "flex", alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                <p style={{ textAlign: 'center' }}>Or</p>
                                <Button onClick={() => setToggleRegister(prev => !prev)} className='start-btn' size='large' shape='round' style={{ textAlign: 'center', border: 'none' }}>Register</Button>
                            </div>
                        </Form>
                    </div > : <div id='user-detail-form'>
                        <h2 className='section-title'>Register Form</h2>
                        <Form

                            requiredMark={false}
                            name="user-details"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}

                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Name"
                                name="username"
                                rules={[
                                    { required: true, message: 'Please input your name!', }]}
                            >
                                <Input />
                            </Form.Item>



                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input your email!' },
                                    { type: "email", message: 'Please input correct email!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Phone"
                                name="mobile"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <MaskedInput mask='0000 0000 000' name="phone" />

                            </Form.Item>
                            <Form.Item
                                label="Cnic"
                                name="cnic"
                                rules={[{ required: true, message: 'Please input your Cnic!' }]}
                            >
                                <MaskedInput mask='00000 0000000 0' name="phone" />

                            </Form.Item>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[{ required: true, message: 'Please input your address!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                className='city-item'
                                label="City"
                                name="city"
                                rules={[{ required: true, message: 'Please select your city!' }]}
                            >
                                <Select>
                                    {cities.map(c => <Select.Option value={c}>{c}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                label="Confirm"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item className='submit-item' wrapperCol={{ span: 24 }} style={{ marginBottom: 16 }}>
                                <Button loading={isRegisterLoading} className='start-btn' type="primary" htmlType="submit" size='large' shape='round'>
                                    Register
                                </Button>
                            </Form.Item>
                            <div style={{ display: "flex", alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                <p style={{ textAlign: 'center' }}>Already Have an Account?</p>
                                <Button onClick={() => setToggleRegister(prev => !prev)} className='start-btn' size='large' shape='round' style={{ textAlign: 'center', border: 'none' }}>Login</Button>
                            </div>
                        </Form>
                    </div >}

                </div>


            )
        }

    }
}

export default UserForm