import React, { useContext, useEffect } from 'react'
import { HeroBanner } from "../../../assets/images"
import { useNavigate } from 'react-router-dom'
import { QuizContext } from '../../screens/Quiz'
const Hero = ({ isButtonShown }) => {

    const data = useContext(QuizContext)

    useEffect(() => {
        // console.log(data);
    }, [data])

    const navigate = useNavigate()
    return (
        <div id="hero">
            <img className='hero__image' src={HeroBanner} alt="" />
            {isButtonShown && <button className='hero__btn' onClick={() => navigate("quiz")} >Take a Quiz</button>}
        </div>
    )
}

export default Hero