import { Radio } from 'antd';
import React, { useContext, useEffect } from 'react'
import { QuizContext } from '../../screens/Quiz';

// import { callApi } from '../../../config/api';


const QuestionItem = ({ q }) => {
    const data = useContext(QuizContext)

    const onChange = (e) => {
        data.updateQuestion(q.id, true, e.target.value);
    };
    // useEffect(() => {

    //     callApi.get("/check_session.php").then(({ data }) => {
    //         // console.log(data);
    //     }).catch(err => {
    //         console.error(err.message)
    //     })

    //     return () => { }

    // }, [])
    return (
        <div className="question_item">
            <h3>{q?.question}</h3>
            <div className="options-group">
                <Radio.Group size='large' onChange={onChange} value={q.selectedValue}>
                    {q?.options?.map((option, i) => <Radio.Button
                        key={i}
                        style={{ background: '#979797' }}
                        // style={{ background: q.selectedValue === q.correct_answer && "red" }}
                        required

                        value={option?.trim()}>{option}</Radio.Button>)}
                </Radio.Group>
            </div>
        </div >
    )
}

export default QuestionItem