import React, { Suspense, lazy } from 'react'
const Hero = lazy(() => import("../components/Homepage/Hero"))
const Leaderboard = lazy(() => import("../components/Homepage/Leaderboard"))
const Slider = lazy(() => import("../components/Homepage/Silder"))
const RewardSlider = lazy(() => import("../components/Homepage/RewardSlider"))
const Terms = lazy(() => import("../components/Homepage/Terms"))



const Home = () => {
    return (
        <Suspense fallback={<></>}>
            <Hero isButtonShown />
            <Leaderboard />
            <Slider />
            <div style={{ background: "#ff6600" }}>
                <RewardSlider />
                <Terms />
            </div>
        </Suspense>
    )
}

export default Home