import axios from "axios";

const api = "/"
// const api = "https://iknowfast.mcldev.com/"

const callApi = axios.create({
    baseURL: api
});

export { api, callApi }
