import React, { useState } from 'react';
import { Menu, Drawer } from 'antd';
import "../../Header.css"
import { IoMdClose } from "react-icons/io";
import { HiOutlineBars2 } from "react-icons/hi2";
import { GoArrowRight } from "react-icons/go";

import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiBars3, HiMinus } from "react-icons/hi2";

import { LiaBarsSolid } from "react-icons/lia";


const { SubMenu } = Menu;

const NestedNestedMenu = () => {
    const [searchText, setSearchText] = useState("")

    const [toggleDrawer, setToggleDrawer] = useState(false)

    const openDrawer = () => setToggleDrawer(prev => true)
    const closeDrawer = () => setToggleDrawer(prev => false)

    const [toggleMDrawer, setToggleMDrawer] = useState(false)

    const openMDrawer = () => setToggleMDrawer(!toggleMDrawer)



    const homepage = "https://fast-cables.com/"
    return (
        <>
            <header id='header-d'>
                <div className="logo"><a href={homepage}><img src="./fast-cable-logo.png" alt="" /></a></div>
                <div className='header-right'>
                    <Menu
                        expandIcon={<GoArrowRight size={24} style={{ width: 18 }} />}
                        mode="horizontal" // Set mode to "horizontal"
                        triggerSubMenuAction='hover'
                        style={{ width: '100%', overflowX: 'auto', overflowY: "hidden", borderBottom: "none", justifyContent: "flex-end" }}
                    >

                        <SubMenu key="about-us" title="About Us" >
                            <Menu.Item key="about-us-1"><a className='link-item' href={homepage + "about-us/"} >Who We Are</a></Menu.Item>
                            <Menu.Item key="about-us-2"><a className='link-item' href={homepage + "corporate-philosophy/"} >Corporate Philosophy</a></Menu.Item>
                            <Menu.Item key="about-us-3"><a className='link-item' href={homepage + "our-clients/"}>Our Clients</a></Menu.Item>
                        </SubMenu>

                        <SubMenu key="products" title="Our Products" >
                            <SubMenu key="products-1" title={<a className='link-item' href={homepage + "cables/"} >Cables</a>}>
                                <Menu.Item key="products-1-1"><a className='link-item' href={homepage + "product-category/low-voltage-cables/"} >Low Voltage</a></Menu.Item>
                                <Menu.Item key="products-1-2"><a className='link-item' href={homepage + "product-category/medium-voltage-cables/"}>Medium Voltage</a></Menu.Item>
                                <Menu.Item key="products-1-3"><a className='link-item' href={homepage + "product-category/conductors/"}>Conductors</a></Menu.Item>
                            </SubMenu>
                            <SubMenu key="products-2" title={<a className='link-item' href={homepage + "lights/"} >Lights</a>}>
                                <Menu.Item key="products-2-1"><a className='link-item' href={homepage + "lamps/"}>Lamps</a></Menu.Item>
                                <Menu.Item key="products-2-2"><a className='link-item' href={homepage + "indoor-lights/"} >Indoor Lights</a></Menu.Item>
                                <Menu.Item key="products-2-3"><a className='link-item' href={homepage + "outdoor-lights/"} >Outdoor Lights</a></Menu.Item>
                            </SubMenu>
                            <Menu.Item key="products-3"><a className='link-item' href={homepage + "metals/"} >Metals</a></Menu.Item>
                            <Menu.Item key="products-4"><a className='link-item' href={homepage + "pvc/"} >PVC</a></Menu.Item>
                        </SubMenu>

                        <SubMenu key="services" title={<a href={homepage + "our-services/"} style={{ all: "unset" }}>Services</a>}>
                            <Menu.Item key="services-1"><a className='link-item' href={homepage + "fast-tasdeeq/"}  >Fast Tasdeeq</a></Menu.Item>
                            <Menu.Item key="services-2"><a className='link-item' href={homepage + "fast-tasdeeq-2/"} >Fast Tasdeeq Plus</a></Menu.Item>
                            <Menu.Item key="services-3"><a className='link-item' href={homepage + "fast-tasdeeq-2/#fastapp"} >Fast App</a></Menu.Item>
                        </SubMenu>

                        <Menu.Item key="quality"><a className='link-item' href={homepage + "quality-standards/"} >Quality Standards</a></Menu.Item>
                        <Menu.Item key="fast-quiz"><a className='link-item active' href={"#"} >Fast Quiz</a></Menu.Item>
                        <Menu.Item key="social-impact"><a className='link-item' href={homepage + "csr-and-responsibility/"} >Social Impact</a></Menu.Item>

                    </Menu>
                    <div className="hamburger" onClick={openDrawer}>
                        <HiOutlineBars2 size={40} />
                    </div>
                </div>

                <Drawer
                    width={570}
                    open={toggleDrawer}
                    onClose={closeDrawer}
                    headerStyle={{ display: "none" }}
                    id='desktop-drawer'
                    bodyStyle={{
                        padding: "35px 107px ",
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    <div className="header">
                        <div className="close-hamburger" onClick={closeDrawer}>
                            <IoMdClose size={30} color="#FFFFFF" />
                        </div>
                    </div>
                    <div className="body">
                        {/* Logo */}
                        <img src="./fast-cable-logo.png" alt="" />
                        {/* Search Form */}
                        <form onSubmit={e => {
                            e.preventDefault();
                            window.location.replace(`${homepage}?s=${searchText}`)
                        }}>
                            <input type="text" value={searchText} onChange={e => setSearchText(e.target.value)} />
                            <input type="submit" value="Search" />
                        </form>
                        {/* Links */}
                        <ul>
                            <li><a href={"https://fasteshop.pk/"}><strong>Fast e-shop</strong></a></li>
                            <li><a href={homepage + "careers/"}>Careers</a></li>
                            <li><a href={homepage + "network/"}>Network</a></li>
                            <li><a href={homepage + "knowledge-center/"}>Knowledge Center</a></li>
                            <li><a href={homepage + "contact-us/"}><strong>Contact Us</strong></a></li>
                        </ul>
                        {/* Socials */}
                        <div className="socials">
                            <a href="https://www.facebook.com/fastcablesltd/"><FaFacebook /></a>
                            <a href="https://twitter.com/FastCablesLtd"><FaXTwitter /></a>
                            <a href="https://linkedin.com/company/fast-cables-limited"><FaLinkedin /></a>
                            <a href="https://www.youtube.com/@fastcables2063"><FaYoutube /></a>
                        </div>
                        {/* Text */}
                        <h4 className="headingText">CABLES | LIGHTS | METALS | PVC</h4>
                    </div>
                </Drawer>
            </header >
            <header id='header-m'>
                <div className="logo"><a href={homepage}><img src="./fast-cable-logo.png" alt="" /></a></div>
                <div className="hamburger-m" onClick={openMDrawer}>{toggleMDrawer ? <HiMinus size={33} color='#FFF' /> : <LiaBarsSolid size={32} color='#FFF' />}</div>

                <Drawer
                    placement='top'
                    height={"auto"}
                    open={toggleMDrawer}
                    onClose={openMDrawer}
                    headerStyle={{ display: "none" }}
                    id='mobile-drawer'>
                    <Menu
                        // expandIcon={<GoArrowRight size={24} style={{ width: 18 }} />}
                        mode="inline" // Set mode to "horizontal"
                        // triggerSubMenuAction='click'
                        style={{ width: '100%', overflowX: 'auto', overflowY: "hidden", borderBottom: "none", justifyContent: "flex-end" }}
                    >

                        <SubMenu key="about-us" title="About Us" >
                            <Menu.Item key="about-us-1"><a className='link-item' href={homepage + "about-us/"} >Who We Are</a></Menu.Item>
                            <Menu.Item key="about-us-2"><a className='link-item' href={homepage + "corporate-philosophy/"} >Corporate Philosophy</a></Menu.Item>
                            <Menu.Item key="about-us-3"><a className='link-item' href={homepage + "our-clients/"}>Our Clients</a></Menu.Item>
                        </SubMenu>

                        <SubMenu key="products" title="Our Products" >
                            <SubMenu key="products-1" title={<a className='link-item' href={homepage + "cables/"} >Cables</a>}>
                                <Menu.Item key="products-1-1"><a className='link-item' href={homepage + "product-category/low-voltage-cables/"} >Low Voltage</a></Menu.Item>
                                <Menu.Item key="products-1-2"><a className='link-item' href={homepage + "product-category/medium-voltage-cables/"}>Medium Voltage</a></Menu.Item>
                                <Menu.Item key="products-1-3"><a className='link-item' href={homepage + "product-category/conductors/"}>Conductors</a></Menu.Item>
                            </SubMenu>
                            <SubMenu key="products-2" title={<a className='link-item' href={homepage + "lights/"} >Lights</a>}>
                                <Menu.Item key="products-2-1"><a className='link-item' href={homepage + "lamps/"}>Lamps</a></Menu.Item>
                                <Menu.Item key="products-2-2"><a className='link-item' href={homepage + "indoor-lights/"} >Indoor Lights</a></Menu.Item>
                                <Menu.Item key="products-2-3"><a className='link-item' href={homepage + "outdoor-lights/"} >Outdoor Lights</a></Menu.Item>
                            </SubMenu>
                            <Menu.Item key="products-3"><a className='link-item' href={homepage + "metals/"} >Metals</a></Menu.Item>
                            <Menu.Item key="products-4"><a className='link-item' href={homepage + "pvc/"} >PVC</a></Menu.Item>
                        </SubMenu>

                        <SubMenu key="services" title={<a href={homepage + "our-services/"} style={{ all: "unset" }}>Services</a>}>
                            <Menu.Item key="services-1"><a className='link-item' href={homepage + "fast-tasdeeq/"}  >Fast Tasdeeq</a></Menu.Item>
                            <Menu.Item key="services-2"><a className='link-item' href={homepage + "fast-tasdeeq-2/"} >Fast Tasdeeq Plus</a></Menu.Item>
                            <Menu.Item key="services-3"><a className='link-item' href={homepage + "fast-tasdeeq-2/#fastapp"} >Fast App</a></Menu.Item>
                        </SubMenu>

                        <Menu.Item key="quality"><a className='link-item' href={homepage + "quality-standards/"} >Quality Standards</a></Menu.Item>
                        <Menu.Item key="fast-quiz"><a className='link-item active' href={"#"} >Fast Quiz</a></Menu.Item>
                        <Menu.Item key="social-impact"><a className='link-item' href={homepage + "csr-and-responsibility/"} >Social Impact</a></Menu.Item>

                    </Menu>
                </Drawer>
            </header >
        </>
    );
}

export default NestedNestedMenu;
